.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

.login-page {
  margin-top: 1em; }
  .login-page .login-form-nav {
    margin-bottom: 1.25em; }
    .login-page .login-form-nav .tab-pane {
      margin-top: 1em; }
  .login-page .login {
    margin-bottom: 0.938em; }
    .login-page .login .error-message-form {
      padding: 0.625em;
      background-color: #c00;
      color: #fff;
      border-radius: 0.188em; }

.registration-page .privacy-policy {
  margin-top: 1rem; }

.login-banner {
  background-image: url("../images/account.jpg");
  background-position-y: 40%; }

.equal-height .card {
  width: 100%; }

.track-order-header {
  font-size: 1.75rem; }

.request-password-title {
  font-size: 1.5rem; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

.form-group > span:not(.info-icon) {
  font-size: 80%; }

.form-control {
  color: var(--color-grey6);
  background-color: var(--color-white); }

::-moz-placeholder, .form-control::-moz-placeholder {
  color: var(--color-grey5); }

::placeholder,
.form-control::placeholder {
  color: var(--color-grey5); }

.floating-label {
  position: relative;
  margin-bottom: 0.625rem; }
  .floating-label .form-control {
    height: 3.75rem; }
  .floating-label label {
    font-size: 0.875rem;
    position: absolute;
    top: 0.9375rem;
    left: 0.75rem;
    margin: 0;
    opacity: 0;
    transition: all .3s ease; }
  .floating-label input {
    transition: all .3s ease; }
    .floating-label input:-webkit-autofill, .floating-label input:not(:placeholder-shown) {
      padding: 1.5rem 2.25rem 0.375rem 0.75rem;
      color: var(--color-black); }
      .floating-label input:-webkit-autofill ~ label, .floating-label input:not(:placeholder-shown) ~ label {
        transform: translateY(-0.625rem);
        opacity: 0.7;
        color: var(--color-grey5); }
  .floating-label select {
    color: var(--color-grey5);
    transition: all .3s ease; }
  .floating-label.focused select {
    padding: 1.5rem 2.25rem 0.375rem 0.75rem;
    color: var(--color-black); }
    .floating-label.focused select + label {
      transform: translateY(-0.625rem);
      opacity: 0.7;
      color: var(--color-grey5); }

form.floating .btn-form-inline {
  height: calc(100% - 0.625rem); }

.login-form-nav .nav-item > .nav-link {
  color: var(--color-black); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.form-group.required .form-control-label::before {
  content: "*";
  color: #FF595E; }

.form-group > span:not(.info-icon) {
  font-size: 80%; }

.form-control {
  color: var(--color-grey6);
  background-color: var(--color-white); }

::-moz-placeholder, .form-control::-moz-placeholder {
  color: var(--color-grey5); }

::placeholder,
.form-control::placeholder {
  color: var(--color-grey5); }

.floating-label {
  position: relative;
  margin-bottom: 0.625rem; }
  .floating-label .form-control {
    height: 3.75rem; }
  .floating-label label {
    font-size: 0.875rem;
    position: absolute;
    top: 0.9375rem;
    left: 0.75rem;
    margin: 0;
    opacity: 0;
    transition: all .3s ease; }
  .floating-label input {
    transition: all .3s ease; }
    .floating-label input:-webkit-autofill, .floating-label input:not(:placeholder-shown) {
      padding: 1.5rem 2.25rem 0.375rem 0.75rem;
      color: var(--color-black); }
      .floating-label input:-webkit-autofill ~ label, .floating-label input:not(:placeholder-shown) ~ label {
        transform: translateY(-0.625rem);
        opacity: 0.7;
        color: var(--color-grey5); }
  .floating-label select {
    color: var(--color-grey5);
    transition: all .3s ease; }
  .floating-label.focused select {
    padding: 1.5rem 2.25rem 0.375rem 0.75rem;
    color: var(--color-black); }
    .floating-label.focused select + label {
      transform: translateY(-0.625rem);
      opacity: 0.7;
      color: var(--color-grey5); }

form.floating .btn-form-inline {
  height: calc(100% - 0.625rem); }

.form-group > span:not(.info-icon) {
  display: block;
  font-size: 0.6875rem;
  margin-top: 0.625rem; }

.form-control {
  font-family: "Neue Haas Grotesk Text Round", sans-serif;
  font-weight: 400;
  height: 2.875rem;
  border: 0.125rem solid var(--color-grey400);
  color: var(--color-black);
  font-size: 0.875rem; }
  @supports (-webkit-overflow-scrolling: touch) {
    .form-control {
      font-size: 1rem; } }
  .form-control:focus {
    color: var(--color-black);
    border-color: var(--color-black);
    outline: 0;
    box-shadow: none; }
  .m-dark-form .form-control {
    background-color: var(--color-dark-input);
    border-color: var(--color-dark-input);
    color: var(--color-white); }
    .m-dark-form .form-control::-moz-placeholder {
      color: var(--color-dark-input-placeholder); }
    .m-dark-form .form-control::placeholder {
      color: var(--color-dark-input-placeholder); }

::-moz-placeholder, .form-control::-moz-placeholder {
  color: var(--color-grey400);
  font-size: 0.875rem; }

::placeholder,
.form-control::placeholder {
  color: var(--color-grey400);
  font-size: 0.875rem; }

.hidden-label {
  position: relative; }

.custom-select {
  background-image: url("../../images/icons/caret-down.svg");
  background-size: 1.125rem;
  padding-right: 2.5rem; }

.custom-checkbox {
  margin-top: 1.5rem; }
  .custom-checkbox .custom-control-label {
    cursor: pointer;
    margin-left: 0.4375rem; }
    .custom-checkbox .custom-control-label:before {
      border: 0.125rem solid var(--color-black);
      background: transparent;
      width: 1.25rem;
      height: 1.25rem;
      left: -1.875rem;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 0; }
    .custom-checkbox .custom-control-label:after {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      width: 1.25rem;
      height: 1.25rem;
      left: -1.875rem;
      top: 50%;
      transform: translateY(-50%);
      color: var(--color-white); }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background-color: var(--color-accent);
    border-color: var(--color-accent); }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("../../images/icons/check-white.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem; }
  .custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    color: var(--color-black);
    cursor: default; }
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before {
      background-color: var(--color-grey200);
      border-color: var(--color-grey400); }
  .custom-checkbox .custom-control-input:focus ~ .custom-control-label:before {
    outline: 0;
    box-shadow: none; }
  .custom-checkbox .custom-control-input:focus-visible ~ .custom-control-label:before {
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 1px; }

.custom-switch .custom-control-label {
  cursor: pointer;
  left: 1.5625rem; }
  .custom-switch .custom-control-label:before {
    top: 0;
    left: -3.75rem;
    background-color: var(--color-white);
    width: 3.125rem;
    height: 1.5625rem;
    border-radius: 1.25rem;
    border: 0.125rem solid var(--color-black); }
  .custom-switch .custom-control-label:after {
    top: 0.125rem;
    left: -3.625rem;
    background-color: var(--color-black);
    width: 1.3125rem;
    height: 1.3125rem;
    border-radius: 50%; }

.custom-switch .custom-control-input:checked ~ .custom-control-label:before {
  background-color: var(--color-black); }

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  left: -2.8125rem;
  background-color: var(--color-white); }

.custom-switch .custom-control-input:disabled ~ .custom-control-label {
  cursor: default;
  color: var(--color-black); }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: var(--color-grey300);
    border-color: var(--color-grey300); }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label:after {
    background-color: var(--color-white); }

.custom-switch .custom-control-input:focus ~ .custom-control-label:before {
  outline: 0;
  box-shadow: none; }

.custom-switch .custom-control-input:focus-visible ~ .custom-control-label:before {
  box-shadow: none;
  outline: -webkit-focus-ring-color auto 1px; }

.custom-radio .custom-control-label {
  cursor: pointer; }
  .custom-radio .custom-control-label:before {
    border: 0.125rem solid var(--color-black); }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-size: 0.375rem; }

.custom-radio .custom-control-input:disabled ~ .custom-control-label {
  cursor: default;
  color: var(--color-black); }
  .custom-radio .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: var(--color-grey200);
    border-color: var(--color-grey400); }

.custom-radio .custom-control-input:focus ~ .custom-control-label:before {
  outline: 0;
  box-shadow: none; }

.custom-radio .custom-control-input:focus-visible ~ .custom-control-label:before {
  box-shadow: none;
  outline: -webkit-focus-ring-color auto 1px; }

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--color-danger); }

.invalid-feedback {
  color: #EB0000;
  font-size: 0.6875rem;
  margin-top: 0.625rem; }

.login-page .privacy-policy {
  margin-top: 0.625rem; }

.login-page .form-control {
  border-width: 1px;
  border-color: var(--color-black); }
  .login-page .form-control.is-invalid {
    border-color: var(--color-danger); }

.login-page .login-form-nav .nav-tabs {
  flex-direction: column; }
  @media screen and (min-width: 750px) {
    .login-page .login-form-nav .nav-tabs {
      flex-direction: row; } }

.login-page .login-form-nav .nav-item {
  flex: 1 0 50%; }
  .login-page .login-form-nav .nav-item > .nav-link {
    transition: 0.3s ease;
    background: none;
    color: var(--color-black);
    font-family: "Neue Haas Grotesk Text Round Bold", sans-serif;
    font-weight: 700; }
    .login-page .login-form-nav .nav-item > .nav-link:active, .login-page .login-form-nav .nav-item > .nav-link:focus, .login-page .login-form-nav .nav-item > .nav-link:hover {
      text-decoration: none;
      color: var(--color-white) !important;
      background: var(--color-blue); }
    .login-page .login-form-nav .nav-item > .nav-link.active {
      color: var(--color-white);
      background: var(--color-black);
      font-family: "Neue Haas Grotesk Text Round", sans-serif;
      font-weight: 400; }

.login-page .login-form-nav .header-login__helpers {
  display: none; }

.login-page .custom-checkbox.remember-me {
  margin-top: 0; }

.login-page .custom-checkbox #add-to-email-list + .custom-control-label::before, .login-page .custom-checkbox #add-to-email-list + .custom-control-label::after {
  top: 2px;
  transform: translateY(0); }

.login-page .custom-checkbox label[for="add-to-email-list"] {
  font-size: 0.875rem; }

.login-recommender {
  padding: 3.75rem 0 6.25rem; }
  .login-recommender h5 {
    margin-bottom: 1.875rem; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.form-group.required .form-control-label::before {
  content: "*";
  color: #AB2117; }

.form-group > span:not(.info-icon) {
  font-size: 80%; }

.form-control {
  color: var(--color-grey6);
  background-color: var(--color-white); }

::-moz-placeholder, .form-control::-moz-placeholder {
  color: var(--color-grey5); }

::placeholder,
.form-control::placeholder {
  color: var(--color-grey5); }

.floating-label {
  position: relative;
  margin-bottom: 0.625rem; }
  .floating-label .form-control {
    height: 3.75rem; }
  .floating-label label {
    font-size: 0.875rem;
    position: absolute;
    top: 0.9375rem;
    left: 0.75rem;
    margin: 0;
    opacity: 0;
    transition: all .3s ease; }
  .floating-label input {
    transition: all .3s ease; }
    .floating-label input:-webkit-autofill, .floating-label input:not(:placeholder-shown) {
      padding: 1.5rem 2.25rem 0.375rem 0.75rem;
      color: var(--color-black); }
      .floating-label input:-webkit-autofill ~ label, .floating-label input:not(:placeholder-shown) ~ label {
        transform: translateY(-0.625rem);
        opacity: 0.7;
        color: var(--color-grey5); }
  .floating-label select {
    color: var(--color-grey5);
    transition: all .3s ease; }
  .floating-label.focused select {
    padding: 1.5rem 2.25rem 0.375rem 0.75rem;
    color: var(--color-black); }
    .floating-label.focused select + label {
      transform: translateY(-0.625rem);
      opacity: 0.7;
      color: var(--color-grey5); }

form.floating .btn-form-inline {
  height: calc(100% - 0.625rem); }

.form-group > span:not(.info-icon) {
  display: block;
  font-size: 0.6875rem;
  margin-top: 0.625rem; }

.form-control {
  font-family: "SuisseIntl-Medium", sans-serif;
  font-display: swap;
  height: 2.875rem;
  border: 0.125rem solid var(--color-grey400);
  color: var(--color-black);
  font-size: 0.875rem; }
  @supports (-webkit-overflow-scrolling: touch) {
    .form-control {
      font-size: 1rem; } }
  .form-control:focus {
    color: var(--color-black);
    border-color: var(--color-black);
    outline: 0;
    box-shadow: none; }
  .m-dark-form .form-control {
    background-color: var(--color-dark-input);
    border-color: var(--color-dark-input);
    color: var(--color-white); }
    .m-dark-form .form-control::-moz-placeholder {
      color: var(--color-dark-input-placeholder); }
    .m-dark-form .form-control::placeholder {
      color: var(--color-dark-input-placeholder); }

::-moz-placeholder, .form-control::-moz-placeholder {
  color: var(--color-grey400);
  font-size: 0.875rem; }

::placeholder,
.form-control::placeholder {
  color: var(--color-grey400);
  font-size: 0.875rem; }

.hidden-label {
  position: relative; }

.custom-select {
  background-image: url("../../images/icons/caret-down.svg");
  background-size: 1.125rem;
  padding-right: 2.5rem; }

.custom-checkbox {
  margin-top: 1.5rem; }
  .custom-checkbox .custom-control-label {
    cursor: pointer;
    margin-left: 0.4375rem; }
    .custom-checkbox .custom-control-label:before {
      border: 0.125rem solid var(--color-black);
      background: transparent;
      width: 1.25rem;
      height: 1.25rem;
      left: -1.875rem;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 0; }
    .custom-checkbox .custom-control-label:after {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      width: 1.25rem;
      height: 1.25rem;
      left: -1.875rem;
      top: 50%;
      transform: translateY(-50%);
      color: var(--color-white); }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background-color: var(--color-accent);
    border-color: var(--color-accent); }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("../../images/icons/check-white.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem; }
  .custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    color: var(--color-black);
    cursor: default; }
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before {
      background-color: var(--color-grey200);
      border-color: var(--color-grey400); }
  .custom-checkbox .custom-control-input:focus ~ .custom-control-label:before {
    outline: 0;
    box-shadow: none; }
  .custom-checkbox .custom-control-input:focus-visible ~ .custom-control-label:before {
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 1px; }

.custom-switch .custom-control-label {
  cursor: pointer;
  left: 1.5625rem; }
  .custom-switch .custom-control-label:before {
    top: 0;
    left: -3.75rem;
    background-color: var(--color-white);
    width: 3.125rem;
    height: 1.5625rem;
    border-radius: 1.25rem;
    border: 0.125rem solid var(--color-black); }
  .custom-switch .custom-control-label:after {
    top: 0.125rem;
    left: -3.625rem;
    background-color: var(--color-black);
    width: 1.3125rem;
    height: 1.3125rem;
    border-radius: 50%; }

.custom-switch .custom-control-input:checked ~ .custom-control-label:before {
  background-color: var(--color-black); }

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  left: -2.8125rem;
  background-color: var(--color-white); }

.custom-switch .custom-control-input:disabled ~ .custom-control-label {
  cursor: default;
  color: var(--color-black); }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: var(--color-grey300);
    border-color: var(--color-grey300); }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label:after {
    background-color: var(--color-white); }

.custom-switch .custom-control-input:focus ~ .custom-control-label:before {
  outline: 0;
  box-shadow: none; }

.custom-switch .custom-control-input:focus-visible ~ .custom-control-label:before {
  box-shadow: none;
  outline: -webkit-focus-ring-color auto 1px; }

.custom-radio .custom-control-label {
  cursor: pointer; }
  .custom-radio .custom-control-label:before {
    border: 0.125rem solid var(--color-black); }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-size: 0.375rem; }

.custom-radio .custom-control-input:disabled ~ .custom-control-label {
  cursor: default;
  color: var(--color-black); }
  .custom-radio .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: var(--color-grey200);
    border-color: var(--color-grey400); }

.custom-radio .custom-control-input:focus ~ .custom-control-label:before {
  outline: 0;
  box-shadow: none; }

.custom-radio .custom-control-input:focus-visible ~ .custom-control-label:before {
  box-shadow: none;
  outline: -webkit-focus-ring-color auto 1px; }

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--color-danger); }

.invalid-feedback {
  color: #EB0000;
  font-size: 0.6875rem;
  margin-top: 0.625rem; }

.form-group > span:not(.info-icon) {
  padding-left: 1rem; }

.form-control {
  font-family: "SuisseIntl-Medium", sans-serif;
  font-display: swap;
  height: 3.125rem;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  font-size: 0.875rem;
  border-radius: 3.125rem;
  padding: 0.375rem 1rem; }
  @supports (-webkit-overflow-scrolling: touch) {
    .form-control {
      font-size: 1rem; } }
  .form-control:focus {
    color: var(--color-black);
    border-color: var(--color-accent);
    outline: 0;
    box-shadow: none; }
  .was-validated .form-control:invalid .form-control .was-validated, .form-control.is-invalid {
    border-width: 0.125rem; }
    .was-validated .form-control:invalid .form-control .was-validated ~ .invalid-feedback,
    .was-validated .form-control:invalid .form-control .was-validated ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
    .form-control.is-invalid ~ .invalid-tooltip {
      padding-left: 1rem; }
  .m-dark-form .form-control {
    background-color: var(--color-dark-input);
    border-color: var(--color-dark-input);
    color: var(--color-white); }
    .m-dark-form .form-control::-moz-placeholder {
      color: var(--color-dark-input-placeholder); }
    .m-dark-form .form-control::placeholder {
      color: var(--color-dark-input-placeholder); }

::-moz-placeholder, .form-control::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.875rem; }

::placeholder,
.form-control::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.875rem; }

.custom-checkbox {
  margin-top: 1rem; }
  .custom-checkbox .custom-control-label {
    margin-left: 0.4375rem; }
    .custom-checkbox .custom-control-label:before {
      border: 1px solid var(--color-black);
      width: 1rem;
      height: 1rem;
      left: -1.875rem; }
    .custom-checkbox .custom-control-label:after {
      font-size: 1rem;
      width: 1rem;
      height: 1rem;
      left: -1.875rem;
      color: var(--color-white); }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background-color: var(--color-black);
    border-color: var(--color-black); }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("../../images/icons/check-white.svg");
    background-repeat: no-repeat;
    background-size: 1.125rem; }
  .custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    color: rgba(0, 0, 0, 0.5); }
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before {
      background-color: var(--color-white);
      border-color: rgba(0, 0, 0, 0.5); }

.custom-radio .custom-control-label {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }
  .custom-radio .custom-control-label:before {
    border: 1px solid var(--color-black); }

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: var(--color-black);
  border-color: var(--color-black); }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background: none;
  background-color: var(--color-black);
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
  top: 0.375rem;
  left: -1.375rem; }

.custom-radio .custom-control-input:disabled ~ .custom-control-label {
  color: rgba(0, 0, 0, 0.5); }
  .custom-radio .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: var(--color-white);
    border-color: rgba(0, 0, 0, 0.5); }

.custom-switch .custom-control-label {
  left: 1.5625rem; }
  .custom-switch .custom-control-label:before {
    top: 50%;
    transform: translateY(-50%);
    left: -3.75rem;
    background-color: var(--color-white);
    width: 2.125rem;
    height: 1rem;
    border-radius: 1.25rem;
    border: 1px solid var(--color-black); }
  .custom-switch .custom-control-label:after {
    top: 50%;
    transform: translateY(-50%);
    left: -3.6875rem;
    background-color: var(--color-black);
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%; }

.custom-switch .custom-control-input:checked ~ .custom-control-label:before {
  background-color: var(--color-accent); }

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  transform: translate(0.75rem, -50%);
  left: -3.3125rem;
  background-color: var(--color-black); }

.custom-switch .custom-control-input:disabled ~ .custom-control-label {
  color: var(--color-black); }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.2); }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label:after {
    background-color: var(--color-white); }
